.App {
  text-align: center;
  background-color: #ced3e2;
  min-width: 1200px;
  height: 100vh;
  overflow: auto;
}

.link {
  text-decoration: none;
  font-size: 15px;
  color: rgb(128, 128, 128);
}

.link:hover {
  text-decoration: none;
  font-size: 15px;
  color: black;
}

#nav-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 35px;
  width: 100%;
  align-items: center;
  background-color: white;
  padding: 2px 1em 0 1em;
  list-style-type: none;
  border-width: 1px;
  border-style: solid;
  border-color: lightgray;
  overflow: auto;
}

.nav-bar-items {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0.3vw;
  cursor: pointer;
}

#mainFrameLayout {
  display: grid;
  grid-template-columns: 27% 44% 27%;
  grid-auto-rows: minmax(100px, auto);
  column-gap: 1%;
  margin: 1em 1em 1em 1em;
  height: 84vh;
}

#upload-module-layout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 2% 2% 2% 2%;
  background-color: white;
  width: 100%;
  min-height: 300px;
  height: 47%;
  padding: 0 1em 0.5em 1em;
  overflow-y: auto;
  gap: 1vw;
}

#upload-input-file {
  display: flex;
  flex-direction: row;
}

#form-file-upload {
  height: 70%;
  text-align: center;
  margin-top: 2vh;
}

#input-file-upload {
  display: none;
}

#label-file-upload {
  font-size: 15px;
  margin-bottom: 5px;
  height: 65%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #f8fafc;
}

#label-file-upload.drag-active {
  background-color: #ffffff;
}

.upload-click-text {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 15px;
  border: none;
  font-family: "Oswald", sans-serif;
  background-color: transparent;
}

.upload-click-text:hover {
  text-decoration-line: underline;
}

.upload-button {
  margin: 1vh 0.2vw;
  width: 45%;
  height: 10%;
  align-items: center;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: transparent 0 0 0 3px, rgba(18, 18, 18, 0.1) 0 6px 20px;
  box-sizing: border-box;
  color: #121212;
  cursor: pointer;
  display: inline-flex;
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-weight: 700;
  justify-content: center;
  line-height: 1;
  outline: none;
  padding: 1rem 1.2rem;
  text-align: center;
  text-decoration: none;
  transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
  white-space: nowrap;
  border: 0;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.upload-button:hover {
  box-shadow: #121212 0 0 0 3px, transparent 0 0 0 0;
}

.upload-button-not-logged-in {
  margin: 1vh 0.2vw;
  width: 100%;
  height: 10%;
  align-items: center;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: transparent 0 0 0 3px, rgba(18, 18, 18, 0.1) 0 6px 20px;
  box-sizing: border-box;
  color: #121212;
  cursor: pointer;
  display: inline-flex;
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-weight: 700;
  justify-content: center;
  line-height: 1;
  outline: none;
  padding: 1rem 1.2rem;
  text-align: center;
  text-decoration: none;
  transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
  white-space: nowrap;
  border: 0;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.upload-button-not-logged-in:hover {
  box-shadow: #121212 0 0 0 3px, transparent 0 0 0 0;
}

#drag-file-element {
  position: absolute;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.title {
  font-size: 23px;
  text-align: left;
  white-space: nowrap;
  font-weight: bold;
}

#upload-module-input-layout {
  display: flex;
  flex-direction: row;
  align-items: start;
  gap: 6%;
}

.upload-module-input-dropdown {
  font-size: 0.8em;
}

.upload-module-ddb-dropdown {
  margin: 20% 0 2vh 0;
  width: 100%;
  font-size: 0.9vw;
}

.upload-module-ddb-dropdown + .upload-module-ddb-dropdown {
  margin: 0 0 2vh 0;
  width: 100%;
  font-size: 0.9vw;
}

#uploadModuleFileName {
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: -2vh;
  font-size: 0.8em;
  font-weight: bold;
}

#upload-module-select-layout {
  display: flex;
  flex-direction: column;
  justify-items: left;
  width: 50%;
}

#simulation-module-layout {
  display: flex;
  flex-direction: column;
  border-radius: 2% 2% 2% 2%;
  background-color: white;
  width: 100%;
  min-height: 400px;
  height: 59%;
  padding: 0.5vh 0.5vw;
  overflow-x: hidden;
  overflow-y: auto;
}

#new-ops-module-layout {
  display: flex;
  flex-direction: column;
  border-radius: 2% 2% 2% 2%;
  background-color: white;
  width: 100%;
  min-height: 350px;
  height: 51%;
  padding: 0 1em 1em 1em;
}

.scenario-slider {
  width: 100%;
  height: 2vh;
  margin: -1vh auto;
}

.customSlider-track {
  top: 8px;
  height: 4px;
  background: #ddd;
}

.customSlider-track.customSlider-track-0 {
  /* color of the track before the thumb */
  background: rgb(104, 117, 217);
}

.customSlider-thumb {
  cursor: pointer;
  /*color for the thumb */
  background: rgb(104, 117, 217);
  /* shape of the thumb: circle */
  width: 20px;
  height: 20px;
  border-radius: 100%;
  /* remove default outline when selected */
  outline: none;
}

.customSlider-thumb:hover {
  box-shadow: 0 0 0 8px rgb(104, 117, 217, 0.2);
}

.customSlider-mark {
  cursor: pointer;
  top: 6px;
  width: 1.5px;
  height: 8px;
  background-color: #aaaaaa;
}

#simulation-bar-chart {
  width: 113%;
  height: 62%;
}

#simulation-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.button {
  margin: 30px;
  width: 7vw;
  align-items: center;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: transparent 0 0 0 3px, rgba(18, 18, 18, 0.1) 0 6px 20px;
  box-sizing: border-box;
  color: #121212;
  cursor: pointer;
  display: inline-flex;
  flex: 1 1 auto;
  font-family: Inter, sans-serif;
  font-size: 2vmin;
  font-weight: 700;
  justify-content: center;
  line-height: 1;
  margin: 0;
  outline: none;
  padding: 1rem 1.2rem;
  text-align: center;
  text-decoration: none;
  transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
  white-space: nowrap;
  border: 0;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button:hover {
  box-shadow: #121212 0 0 0 3px, transparent 0 0 0 0;
}

#first-column-mainframe {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

#scoreboard-layout {
  display: flex;
  flex-direction: column;
  border-radius: 2% 2% 2% 2%;
  background-color: white;
  width: 100%;
  min-height: 250px;
  height: 39%;
  padding: 0 0.5em;
}

#second-column-mainframe {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

#scoreboard-graph {
  height: 70%;
  width: 113%;
}

#simulation-buttons-layout {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1.5vh;
  height: 7%;
  gap: 1vw;
}

#scenario-slider-name {
  text-align: left;
}

#upload-module-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.template-link {
  text-decoration-line: none;
  color: #0000ee;
  font-size: 2vh;
  height: 2vh;
  text-align: center;
  white-space: nowrap;
}

#home-link {
  color: #121212;
}

#faq-page-layout {
  margin: 1em;
}

#authentication-text {
  text-align: center;
  margin-top: 3em;
}

.upload-to-cloud {
  font-size: 0.5em;
}

#remind-signin-text {
  font-size: 0.6em;
  margin-bottom: -0.3vh;
}

#scenario-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0.5vh 0.5vw;
  gap: 1vw;
  height: 10%;
}

.scenario-slider-value {
  margin-bottom: -1vh;
}

#third-column-mainframe {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

#statistics-module-layout {
  display: flex;
  flex-direction: column;
  border-radius: 2% 2% 2% 2%;
  background-color: white;
  width: 100%;
  min-height: 325px;
  height: 50%;
  padding: 0 1em;
}

.statistics-module-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 1vh 1vw;
  font-size: 13px;
  font-weight: 600;
  text-align: left;
  width: 100%;
}

.statistics-module-info-values {
  color: rgb(105, 139, 193);
  font-weight: bold;
}

.statistics-module-info-legend {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0;
}

#scoreboard-top-layout {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0;
}

.simulation-chart-icon {
  width: 80px;
  height: 80px;
  object-fit: cover;
  cursor: pointer;
}

#my-account-icon {
  width: 4vh;
  height: 3.8vh;
  background-color: #e3f2fd;
}

#kinetik-logo {
  object-fit: cover;
}

.menu-link {
  text-decoration: none;
  color: #121212;
}

#upload-module-right-section {
  width: 50%;
}

#upload-logo {
  width: 30%;
  height: 20%;
  object-fit: cover;
}

#files-page-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

#files-page-layout {
  display: flex;
  flex-direction: column;
  gap: 1em;
  margin: 0.5% 2%;
}

.files-type-logo {
  width: 5vh;
  height: 5vh;
  object-fit: cover;
}

.file-type-layout {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5vw;
}

.manage-files-page-header {
  display: flex;
  flex-direction: row;
  justify-content: left;
  text-align: left;
  gap: 3vw;
}

.manage-files-page-buttons {
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  gap: 2vw;
}

.control-panel-upload-flashing-border {
  border: 2px solid goldenrod;
  animation: flashBorder 3s infinite;
}

@keyframes flashBorder {
  0% {
    border-color: goldenrod;
  }
  50% {
    border-color: transparent;
  }
  100% {
    border-color: goldenrod;
  }
}

.flashing-background {
  background-color: goldenrod;
  animation: flashBackground 3s infinite;
}

@keyframes flashBackground {
  0%,
  100% {
    background-color: goldenrod;
  }
  50% {
    background-color: transparent;
  }
}

.simulation-auto-play {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1vw;
  margin: 0 1vw;
}

.simulation-playback-speed {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5vw;
  margin: 0 0.5vw 0 0;
}

#input-file-template-text-layout {
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 3.5vh;
}

.sensitivity-analysis-page-layout {
  display: flex;
  flex-direction: column;
  gap: 1em;
  margin: 0 2%;
}

.loading-backdrop-layout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1vh;
}

.revenue-impact-retangle-layout {
  display: flex;
  flex-direction: row;
  gap: 0;
}

.revenue-impact-retangle {
  height: 5vh;
  border-radius: 2%;
  border-style: solid;
  border-width: 1px;
}

.revenue-impact-retangle + .revenue-impact-retangle {
  border-left-width: 0;
}

.revenue-impact-number {
  font-weight: bold;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 0.8vw;
}
